import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import chroma from "chroma-js";
import type { ActionButtonStyle } from "~/types";
import type * as turf from "@turf/turf";

dayjs.extend(relativeTime);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function slugify(str: string) {
  return str
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^\w\s-]/g, "") // Remove all non-word characters (except hyphens and underscores)
    .replace(/\s+/g, "-") // Replace all spaces with hyphens
    .replace(/--+/g, "-") // Replace multiple consecutive hyphens with a single hyphen
    .trim(); // Trim leading and trailing spaces (if any)
}

export function fromNow(datestring: string | null) {
  return dayjs(datestring).fromNow();
}

export function displayPrice(input: number | string | null | undefined) {
  const price = Number(input) / 100;
  return formatPrice(price ?? 0);
}

export function formatPrice(input: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(input ?? 0);
}

export function isValidUriPath(uri: string): boolean {
  if (!uri.includes("/")) {
    return false;
  }
  try {
    new URL(uri, "http://example.com"); // Base URL to handle relative paths
    return true;
  } catch (e) {
    return false;
  }
}

export function getReadableTextColor(bgColor: string) {
  const whiteContrast = chroma.contrast(bgColor, "white");
  const blackContrast = chroma.contrast(bgColor, "black");
  return whiteContrast > blackContrast ? "white" : "black";
}

export function expandBbox(
  bbox: ReturnType<typeof turf.bbox>,
  factor: number
): [number, number, number, number] {
  const [minX, minY, maxX, maxY] = bbox;
  const width = maxX - minX;
  const height = maxY - minY;
  const expandX = width * factor;
  const expandY = height * factor;
  return [minX - expandX, minY - expandY, maxX + expandX, maxY + expandY];
}

export function getProvider(path: string) {
  return path.includes("cfr2") ? "cloudflare" : "supabase";
}

export function getFileType(filename: string): "image" | "video" | "unknown" {
  const extension = filename.split(".").pop()?.toLowerCase();
  if (!extension) {
    return "unknown";
  }
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

export function stripHtml(html: string): string {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const convertStyleToCssProperties = (
  style: ActionButtonStyle
): React.CSSProperties => {
  return {
    display: "inline-block",
    border: style.border
      ? `${style.border}px solid ${style.borderColor}`
      : "none",
    background: style.background,
    color: style.color,
    borderRadius: style.roundness ? `${style.roundness}px` : "0",
    padding: style.spacing
      ? `${(style.spacing ?? 0) * 0.35}px ${style.spacing}px`
      : "0",
    fontSize: style.fontSize ? `${style.fontSize}px` : "12px",
    fontWeight: style.fontWeight,
    textAlign: "center",
  };
};
